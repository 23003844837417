import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'

import PropTypes from 'prop-types'

import { scale } from '../utils/typography'
import styles from '../styles'

// import 'typeface-space-mono'
// import 'typeface-spectral'

import 'prismjs/themes/prism-solarizedlight.css'
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'
import { Rss } from 'react-feather'

class BlogLayout extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                author
                blogTitle
                siteUrl
              }
            }
          }
        `}
        render={data => {
          const { author, blogTitle } = data.site.siteMetadata
          return (
            <div>
              <div {...styles.container} {...styles.verticalPadding}>
                <Link
                  to="/blog/"
                  css={{
                    display: `inline-block`,
                    textDecoration: `none`
                  }}
                >
                  <h1
                    css={{
                      ...scale(0),
                      color: styles.colors.light,
                      fontWeight: `normal`,
                      lineHeight: 1,
                      margin: 0
                    }}
                  >
                    {blogTitle}
                  </h1>
                </Link>
              </div>
              <div {...styles.container} {...styles.verticalPadding}>
                {this.props.children}
                <div
                  css={{
                    ...scale(-0.3),
                    color: styles.colors.light,
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>
                    © 2019,
                    {` `}
                    <a href="/">{author}</a>
                  </span>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/blog/rss.xml"
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Rss />
                    &nbsp;rss
                  </a>
                </div>
              </div>
            </div>
          )
        }}
      />
    )
  }
}

BlogLayout.propTypes = {
  children: PropTypes.node
}

export default BlogLayout
